@import "../../../commonStyle/var";
@import "../../../commonStyle/tool";
@import "../../../commonStyle/common";
@import "./var";
@import "./reset";
@import "./tool";
@import "./common";
@import "./icon";



header{
  background-color: @main;
  height: @header_height;
  position: relative;
  z-index: 6;
  .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: @header_height;
  }
  .logo{
    display: flex;
    align-items: center;
    overflow: hidden;
    img{
      max-height: 65px;
      max-width: 250px;
    }
  }
  .nav-area{
    display: flex;
    align-items: center;
    .search{
      margin-left: 30px;
      width: 130px;
      height: 30px;
      line-height: 30px;
      color: @light-black;
      font-size: 16px;
      position: relative;
      border-radius: 25px;
      overflow: hidden;
      input{
        height: 100%;
        background-color: #fff;
        padding-left: 10px;
        padding-right: 30px;
        width: 100%;
        display: block;
      }
      .search-btn{
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        height: 100%;
        cursor: pointer;
        background-color: transparent;
        .flex-center;
        img{

        }
      }
    }
  }
  .nav{
    display: flex;
    align-items: center;
    font-size: 16px;
    .nav-item{
      position: relative;
      >a{
        height: @header_height;
        display: flex;
        align-items: center;
        color: #fff;
        padding: 0 18px;
        transition: all .3s;
      }
      &:hover,&.active{
        background-color: #a0139a;
      }
      &:hover{
        .sub-nav{
        }
      }

      .sub-nav{
        position: absolute;
        left: 0;
        top: @header_height;
        background-color: rgba(255, 255, 255, 0.9);
        width: 165px;
        display: none;
        box-shadow: 0 2px 8px rgba(41, 41, 41, 0.1);
        .sub-nav-item{
          display: flex;
          align-items: center;
          height: 50px;
          &.active,&:hover{
            a{
              color: @main;
              &:before{
                transform: scale(1);
              }
            }
          }
          a{
            color: @black;
            width: 100%;
            height: 100%;
            margin-left: 18px;
            padding-left: 20px;
            display: flex;
            align-items: center;
            position: relative;
            &:before{
              content: '';
              position: absolute;
              left: 0;
              top: 23px;
              width: 4px;
              height: 4px;
              display: block;
              transition: all .3s;
              border-radius: 50%;
              background-color: @main;
              transform: scale(0);
            }
          }
        }
      }
    }

  }
}

main{
  background-color: @bg-color;
  padding-bottom: 90px;
}

footer{
  background-color: #e6e6e6;
  .top-box{
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
    .footer-list{
      display: flex;
      >li{
        width: 135px;
        .title{
          font-weight: bold;
          color: @main;
          font-size: 16px;
          line-height: 1.5;
          &:hover{
            font-weight: bold;
          }
        }
        .item,.desc{
          line-height: 1.2;
          margin-top: 15px;
          display: block;
          font-size: 12px;
          color: @light-black;
        }
        .item{
          &:hover{
            color: @black;
          }
        }
        &.concat{
          width: 275px;
          padding-right: 20px;
        }
      }
    }
    .code{
      text-align: center;
      .img{
        .img-wrap(85px);
        background-color: #fff;
      }
      .desc{
        font-size: 12px;
        color: @light-black;
        line-height: 1.5;
        margin-top: 10px;
      }
    }
  }
  .bottom-box{
    background-color: @main;
    font-size: 14px;
    color: #fff;
    a{
      color: #fff;
    }
    >.container{
      padding: 5px 0;
      .top{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-right: -30px;
        line-height: 1.5;
        .title, a{
          display: inline-block;
        }
        .title{
          margin-right: 5px;
        }
        a{
          margin-right: 30px;
        }
      }
      .bottom{
        line-height: 1.5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .bottom-left{
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.right-info{
  position: fixed;
  right: 0;
  top: 45%;
  z-index: 3;
  .list{
    >li{
      &.minip-code-wrapper{
        position: relative;
        .code{
          position: absolute;
          left: -140px;
          top: 0;
          width: 130px;
          height: 130px;
          background-color: #fff;
          display: none;
          text-align: center;
          line-height: 130px;
          box-shadow: 0 0 9px 0 rgba(29, 2, 28, 0.2);
          &:before{
            content: '';
            position: absolute;
            right: -54px;
            top: 15px;
            .triangle-left;
            border-left: 5px solid #fff;
            border-top: 9px solid transparent;
            border-right: 50px solid transparent;
            border-bottom: 9px solid transparent
          }
        }
      }
      a{
        position: relative;
        z-index: 1;
        line-height: 1.2;
        .flex-center;
        border-top: 0;
        width: 45px;
        height: 45px;
        background-color: #fff;
        color: @black;
        font-size: 14px;
        &:hover{
          color: @main;
        }
        &.active{
          background-color: @main;
          color: #fff;
        }
      }
      & + li{
        margin-top: 1px;
      }
    }
  }
}

.page-index{
  .banner{
    margin-bottom: -65px;
    max-height: 540px;
    &:after{
      display: none;
    }
    .swiper-container{
      max-height: 540px;
    }
    .swiper-button-prev,.swiper-button-next{
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background: none;
      background-color: rgba(255, 255, 255, 0.5);
      .flex-center;
      transition: all .3s;
      margin-top: -18px;
      &:hover{
        background-color: rgba(255, 255, 255, 0.8);
        img{
          transform: translateX(-2px);
        }
      }
      img{
        transition: all .3s;
        width: 11px;
        height: 19px;
      }
    }
    .swiper-button-prev{
      left: 5%;
    }
    .swiper-button-next{
      right: 5%;
      transform: rotateY(180deg);
    }
  }
  .panel{
    position: relative;
    z-index: 3;
  }
  .news-panel{
    .panel-content{
      margin-top: 5px;
      display: flex;
      .news-swiper{
        .img-wrap(680px,424px);
        .swiper-pagination{
          right: 20px;
          bottom: 15px;
          left: unset;
          width: auto;
          .swiper-pagination-bullet{
            &.swiper-pagination-bullet-active{
              background-color:  @main;
            }
          }
        }
        //.swiper-prev,.swiper-next{
        //  position: absolute;
        //  top: 50%;
        //  margin-top: -9px;
        //  z-index: 3;
        //  cursor: pointer;
        //  padding: 10px;
        //}
        //.swiper-prev{
        //  left: 10px;
        //}
        //.swiper-next{
        //  right: 10px;
        //  transform: rotateY(180deg);
        //}
        .swiper-slide{
          //.desc{
          //  position: absolute;
          //  left: 0;
          //  bottom: 0;
          //  width: 100%;
          //  background-color: rgba(137, 12, 132, 0.2);
          //  height: 50px;
          //  display: flex;
          //  align-items: center;
          //  padding: 0 50px 0 15px;
          //  color: #fff;
          //  font-size: 18px;
          //}
        }
      }
      .news-panel-content{
        margin-left: 30px;
        width: 430px;
        >li{
          height: 90px;
          border-bottom: 1px solid @border;
          position: relative;
          padding: 20px 0;
          &:first-child{
            height: 65px;
            padding-top: 0;
          }
          &:hover,&.active{
            .title{
              color: @main;
            }
          }
          .title{
            font-size: 18px;
            color: @black;
            line-height: 1.5;
          }
          .time{
            font-size: 14px;
            color: @gray;
            position: absolute;
            right: 0;
            bottom: 12px;
          }
        }
      }
    }
  }
  .report-panel{
    .report-list{
      display: flex;
      align-items: center;
      margin-left: -30px;

      >li{
        margin-left: 30px;
        position: relative;
        .img-wrap(360px, 224px);
        &:hover{
          .content{
            opacity: 1;
            transform: scale(1);
          }
        }
        a{
          .mask-wrapper;
        }
        .content{
          position: absolute;
          left: 50%;
          top: 50%;
          z-index: 2;
          background-color: rgba(255, 255, 255, 0.8);
          color: @main;
          width: 296px;
          height: 126px;
          margin-top: -63px;
          margin-left: -148px;
          padding: 0 18px;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          opacity: 0;
          transition: all .3s;
          transform: scale(.8);
          >.title{
            height: 50px;
            line-height: 1.5;
            font-size: 18px;
            margin-bottom: 10px;
          }
        }
      }

    }
  }
  .alumni-panel{
    .panel-content{
      display: flex;
      flex-direction: column;

      .alumni-top-wrapper{
        display: flex;
        justify-content: space-between;
        .alumni-main{
          display: flex;
          width: calc(50% - 20px);
          &:hover{
            .content{
              >.title{
                color: @main;
              }
            }
          }
          .img{
            .img-wrap(210px, 130px);
          }
          .content{
            flex-grow: 1;
            margin-left: 20px;
            border-bottom: 1px solid @border;
            >.title{
              font-size: 18px;
              color: @black;
              line-height: 1.5;
              height: 55px;
              margin-bottom: 25px;
            }
            .time{
              font-size: 14px;
              color: @gray;
            }
          }
        }
      }
      .alumni-list{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        >li{
          width: calc(50% - 20px);
          margin-top: 25px;
          line-height: 1.5;
          &:hover{
            .title{
              color: @main;
            }
          }
          a{
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .title{
            color: @light-black;
            font-size: 16px;
            max-width: 480px;
            .text-overflow;
          }
          .time{
            color: @gray;
            font-size: 14px;
            flex-shrink: 0;
          }
        }
      }
    }
  }
}

.page-search{
  .panel{
    margin-top: 30px;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    form{
      margin-bottom: 40px;
      width: 460px;
      font-size: 14px;
      border-radius: 25px;
      color: @black;
      overflow: hidden;
      height: 35px;
      line-height: 35px;
      position: relative;
      input{
        height: 35px;
        line-height: 35px;
        width: 100%;
        border-radius: 25px;
        border: 1px solid @border;
        padding-left: 20px;
        padding-right: 40px;
        background-color: #fafafa;
      }
      .search-btn{
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 40px;
        .flex-center;
        background-color: transparent;
      }
    }
    .search-count{
      line-height: 1.5;
      font-size: 14px;
      color: @gray;
    }
    .search-result-list{
      margin-bottom: @search_list_space;
      >li{
        border-bottom: 1px solid @border;
        padding-bottom: 25px;
        padding-top: 25px;
        position: relative;
        &:hover{
          a{
            >.title{
              color: @main;
            }
            >.desc{
              color: @black;
            }
          }
        }
        a{
          >.title{
            font-size: 18px;
            line-height: 1.5;
            color: @black;
            .text-overflow;
            margin-bottom: 15px;
          }
          >.desc{
            line-height: 1.5;
            font-size: 14px;
            color: @light-black;
          }
        }
      }
    }
  }
}

.page-aside-layout{
  .subject-content{
    .subject-title{
      margin-bottom: 30px;
    }
  }

  .article-list{
    >li{
      &:first-child{
        padding-top: 0;
      }
    }
  }

  .events-wrapper{
    position: relative;
    &:before{
      content: '';
      position: absolute;
      left: 50%;
      top: 45px;
      bottom: 0;
      margin-left: -1px;
      border-left: 2px solid @border;
    }
    &:after{
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -4px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: @border;
    }
    .year{
      margin-bottom: 15px;
      .text{
      }
    }
    .event-list{
      padding-left: 45px;
      padding-right: 45px;
      padding-bottom: 80px;
      margin-bottom: 10px;
      position: relative;
      >li{
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        &:after{
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          margin-left: -4px;
          margin-top: -4px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: @border;
          transition: all .3s;
        }
        &:before{
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 60px;
          border-bottom: 1px solid @border;
          animation-timing-function: cubic-bezier(.24,1.18,.31,.94)s;
        }
        @keyframes line-emit
        {
          from {width: 0;}
          to {width: 60px;}
        }

        &:hover{
          &:after{
            background-color: @main;
          }
          &:before{
            animation: line-emit .5s;
            border-color: @main;
          }
          .content{
            .time,.desc{
              color: @main;
            }
          }
        }
        &:nth-child(odd){
          flex-direction: row-reverse;
        }
        &:nth-child(even){
          &:before{
            transform: rotateY(-180deg) translateX(100%);
          }
        }
        .img{
          flex-shrink: 0;
          border-radius: 4px;
          .img-wrap(320px, 200px);
        }
        .content{
          flex-shrink: 0;
          width: 320px;
          position: relative;
          .time{
            font-weight: bold;
            margin-bottom: 5px;
            line-height: 1.5;
            color: @black;
            font-size: 16px;
          }
          .desc{
            color: @light-black;
            line-height: 1.5;
            font-size: 14px;
          }
        }
      }
    }
  }

  .org-wrapper{
    & + .org-wrapper{
      padding-top: 30px;
    }
    .org-title{
      font-size: 18px;
      color: @black;
      line-height: 1.5;
      margin-bottom: 10px;
    }
  }

  .subject-title{
    margin-bottom: 5px;
  }

  .main-panel{
    padding-bottom: @search_list_space;
    .concat-list{
      margin: 30px 0;
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      font-size: 14px;
      color: @main;
      background-color: @bg-gray;
      padding-top: 30px;
      padding-bottom: 30px;
      text-align: center;
      li{
        width: 30%;
        .icon-wrapper{
          .flex-center;
          height: 25px;
          margin-bottom: 10px;
        }
        .desc{
          line-height: 1.2;
        }
      }
    }
    .map{
      height: 445px;
      width: 100%;
      border: 1px solid @border;
    }
  }

}

.page-detail{
  .main-panel{
    padding-left: 90px;
    padding-right: 90px;
    padding-bottom: @search_list_space;
    .meta{
      text-align: center;
      border-bottom: 1px solid @border;
      padding-top: 50px;
      padding-bottom: 30px;
      .title{
        font-size: 18px;
        color: @black;
        line-height: 1.5;
        margin-bottom: 20px;
      }
      .time{
        font-size: 14px;
        color: @gray;
        line-height: 1.2;
      }
    }
  }
}


.page-course{
  .teachers-intro-panel{
    .teachers-swiper{
      width: 100%;
      overflow: hidden;
      padding: 15px;
      margin: -15px;
      box-sizing: content-box;
      .swiper-slide{
        background-color: #fff;
        height: 350px;
      }
    }
    .mini-page{
      justify-content: flex-end;
    }
  }
}

.course-panel{
  display: flex;
  .img{
    margin-right: 40px;
    .img-wrap(600px, 374px);
  }
  .content{
    flex-grow: 1;
    padding-top: 25px;
    .panel-title-deco{
      margin-bottom: 25px;
    }
    .text{
      .load-more{
        margin-top: 35px;
        &:after{
          transform: rotate(-90deg);
        }
      }
    }
  }
}


.page-course-index{
  .panel{
    padding-top: 30px;
  }
  .course-list{
    margin-left: -30px;
    display: flex;
    >li{
      margin-left: 30px;
      width: 360px;
      .mask-wrapper;
      &:before{
        height: 224px;
      }
      &:hover{
        a{
          >.desc{
            color: @main;
          }
          .img{
            .mask-content{
              opacity: 1;
              transform: translateY(0);
            }
          }
        }
      }
      a{
        display: block;
        .img{
          position: relative;
          .img-wrap(100%, 224px);
          margin-bottom: 15px;
          .mask-content{
            transition: all .3s;
            opacity: 0;
            transform: translateY(5px);
            position: absolute;
            left: 0;
            right: 0;
            padding: 0 10%;
            width: 100%;
            height: 100%;
            z-index: 1;
            .flex-center;
            flex-direction: column;
            .desc{
              color: #fff;
              font-size: 20px;
              line-height: 1.5;
              margin-bottom: 10px;
            }
            .btn{
              width: 80px;
            }
          }
        }
        .desc{
          text-align: center;
          color: @black;
          line-height: 1.2;
          font-size: 16px;
        }
      }
    }
  }
}

.page-legal-business-lecture{
  .main-panel{
    padding-bottom: @search_list_space;
    .panel-title-bar{
      margin-bottom: 5px;
    }
    .legal-business-lecture-list{
      >li{
        &:first-child{
          padding-top: 0;
        }
      }
    }
  }
}

.page-annual-report{
  .subject-content{
    .subject-title{
      margin-bottom: 30px;
    }
  }
}

.page-employment{
  .teachers-intro-panel{
    .panel-title-wrapper{

    }
    .tab-list{
      margin-bottom: 0;
    }
  }
  .join-table{
    tr{
      cursor: pointer;
    }
  }
  .pagination{
    margin-top: -25px !important;
  }
}

.member-list-wrapper{
  padding-bottom: 60px;
  &.member-list-preview{
    .member-list{
      padding-top: 5px;
      overflow: hidden;
      height: 540px;
      padding-bottom: 30px;
      box-sizing: content-box;
    }
    .btn-get-member-list-wrapper{
      display: block;
    }
  }
  .btn-get-member-list-wrapper{
    display: none;
    margin-top: 30px;
  }
  &:last-child{
    padding-bottom: 0;
  }
}

.ajax-page-loading{
  position: relative;
  @keyframes rotate {
    from {transform: rotate(0);}
    to {transform: rotate(360deg);}
  }

  &.ajax-page-loading__active{
    .ajax-page-loading-icon,.loading-mask{
      display: block;
    }
  }

  .ajax-page-loading-icon{
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -12px;
    z-index: 11;
    animation: rotate 1s infinite linear;
    .icon-loading;
    display: none;
  }
  .loading-mask{
    background-color: #fff;
    opacity: .3;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: none;
  }
}

