@import "./var";

.iconGen(@icon,@width:10px,@height:10px){
  background: url("@{icon_url}@{icon}.png") no-repeat center;
  width: @width;
  height: @height;
  background-origin:content-box;
  background-size: contain;
  display: inline-block;
}

.icon-loading{
  .iconGen(icon-loading,24px,24px);
}

