@import "../../../commonStyle/var";
@import "../../../commonStyle/tool";
@import "./var";


.btn{
  &.btn-s{
    font-size: 14px;
    width: 80px;
    height: 26px;
    line-height: 26px;
    padding: 0;
  }
  &.btn-m{
    font-size: 14px;
    height: 32px;
    line-height: 32px;
    padding: 0 15px;
  }
}

.container{
  width: @container_width;
  margin-left: auto;
  margin-right: auto;
}

.container-fluid{
  min-width: @container_width;
}

.banner{
  max-height: 320px;
  overflow: hidden;
  margin-bottom: -60px;
  position: relative;
  &:after{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.15);
    z-index: 1;
  }
  .title{
    position: absolute;
    z-index: 2;
    width: 80%;
    .margin-center;
    top: 50%;
    left: 50%;
    margin-top: -30px;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    font-size: 18px;
    line-height: 1.5;
  }
}

.panel-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  >.title{
    font-size: 24px;
    color: @main;
    display: flex;
    align-items: baseline;
    .sub-title{
      font-size: 14px;
      color: @light-black;
      margin-left: 5px;
    }
  }
  .more{
    font-size: 14px;
    color: @gray;
  }
}

.panel{
  .container;
  background-color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
  position: relative;
  z-index: 1;
  & + &{
    margin-top: 20px;
  }
  .panel-title-wrapper{
    display: flex;
    align-items: baseline;
    &.panel-title-wrapper__border-bottom{
      border-bottom: 1px solid @border;
      margin-bottom: 25px;
    }
  }
  .panel-title{
    height: 65px;
    padding-top: 30px;
    padding-bottom: 15px;
  }
  .panel-title-bar{
    flex-shrink: 0;
    font-size: 24px;
    color: @black;
    line-height: 1.5;
    padding-top: 25px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .panel-title-deco{
    font-size: 24px;
    color: @black;
    position: relative;
    padding-left: 10px;
    &:before{
      content: '';
      position: absolute;
      left: 0;
      top: 14%;
      width: 5px;
      height: 76%;
      background-color: @main;
    }
  }
  .panel-content{
    margin-top: 5px;
  }
}

.subject-wrapper{
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  .subject-list{
    width: 230px;
    flex-shrink: 0;
    margin-right: 60px;
    border-top: 1px solid @border;
    >li{
      height: 80px;
      font-size: 18px;
      border-bottom: 1px solid @border;
      transition: all .3s;
      a{
        padding-left: 30px;
        padding-right: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: @black;
        height: 100%;
        transition: all .3s;
      }
      &:hover,&.active{
        background-color: @main;
        a{
          color: #fff;
          img{
            filter: brightness(5);
          }
        }
      }
    }
  }
  .subject-content{
    width: 850px;
    .subject-title{
      font-size: 24px;
      color: @black;
      display: flex;
      align-items: baseline;
      padding-bottom: 15px;
      border-bottom: 1px solid @border;
      .sub-title{
        font-size: 14px;
        color: @gray;
        display: inline-block;
        margin-left: 5px;
      }
    }
    .recruit-list{
      padding-top: 0;
    }
  }
}

.icon-close{
  .bg('icon/icon-close',png);
  width: 20px;
  height: 20px;
}

//popup-reset start
.popup-wrapper{
  border-radius: 0;
  padding: 0 35px 35px;
  .popup-header{
    padding: 25px 0;
    border-bottom: 1px solid @border;
    position: relative;
    h4.title{
      font-size: 18px;
      color: @black;
      line-height: 1.5;
      padding-right: 40px;
      span{
        display: inline;
      }
    }
    .icon-close{
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -10px;
    }
  }
  .popup-content{
    padding-left: 0;
    padding-right: 0;
  }
}
//popup-reset end

.bread-nav{
  display: flex;
  align-items: center;
  color: @gray;
  font-size: 14px;
  padding: 20px 0;
  >li{
    display: inline-flex;
    & + li{
      &:before{
        content: '>';
        display: inline-flex;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
}

.load-more{
  font-size: 16px;
  color: @main;
  cursor: pointer;
  display: flex;
  align-items: center;
  &.active{
    &:after{
      transform: rotate(-180deg);
    }
  }
  &:after{
    content: '';
    display: inline-block;
    .bg('icon/icon-allow_down_main',png);
    width: 13px;
    height: 8px;
    margin-left: 10px;
    transition: all .3s;
  }
}

.member-list{
  margin-left: -40px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -30px;
  &.member-list-3{
    margin-left: -30px;
    margin-bottom: -30px;
    >li{
      width: 360px;
      margin-left: 30px;
      margin-bottom: 30px;
    }
  }
  >li{
    flex-shrink: 0;
    margin-bottom: 30px;
    height: 160px;
    padding: 20px 25px;
    border: 1px solid @border;
    width: 405px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: 40px;
    background-color: @bg-gray;
    transition: all .3s;
    &:hover{
      background-color: #fff;
      border-color: transparent;
      box-shadow: 0 0 11px 3px rgba(0, 0, 0, 0.1);
      .content{
        .member-name-wrapper{
          color: @main;
          .rank{
            color: @main;
          }
        }
      }
    }
    .img{
      .avatar(120px);
      margin-right: 20px;
    }
    .content{
      padding-top: 20px;
      flex-grow: 1;
      .rank{
        font-size: 14px;
        display: inline-block;
        margin-left: 10px;
        color: @gray;
      }
      .btn{
        align-items: baseline;
        height: 30px;
        line-height: 30px;
        padding: 0 15px;
        margin-bottom: 15px;
        font-size: 18px;
        cursor: default;
        .rank{
          color: #fff;
        }
      }
      .member-name-wrapper{
        font-size: 18px;
        color: @black;
        line-height: 1.5;
        display: flex;
        margin-bottom: 15px;
        align-items: baseline;
      }
      .title{
        font-size: 18px;
        color: @black;
        line-height: 1.5;
        display: flex;
        margin-bottom: 15px;
        align-items: baseline;
        .rank{
          font-size: 14px;
          display: inline-block;
          margin-left: 10px;
          color: @gray;
        }
      }
      .desc{
        font-size: 14px;
        color: @light-black;
        line-height: 1.2;
      }
    }
  }
}

.mini-page{
  font-size: 14px;
  display: flex;
  align-items: center;
  .mini-page-item{
    width: 20px;
    height: 20px;
    .flex-center;
    color: @main;
    display: inline-flex;
    border: 1px solid @border;
    border-radius: 50%;
    cursor: pointer;
    padding-left: 1px;
    & + .mini-page-item{
      margin-left: 10px;
    }
    &.active,&:hover{
      border-color: @main;
      color: #fff;
      background-color: @main;
    }
  }
}

.multi-col-text{
  line-height: 1.8;
  color: @light-black;
  font-size: 16px;
}

.legal-business-lecture-list{
  >li{
    padding-top: 30px;
    .img-scale;
    &:hover{
      a{
        .content{
          .title{
            color: @main;
          }
          .btn{
            border-color: @main;
            color: #fff;
            background-color: @main;
          }
        }
      }
    }
    a{
      display: flex;
      .img{
        .img-wrap(320px, 200px);
        margin-right: 40px;
      }
      .content{
        border-bottom: 1px solid @border;
        width: 770px;
        .title{
          font-size: 18px;
          color: @black;
          line-height: 1.5;
          margin-bottom: 10px;
          display: flex;
          span{
            width: 100%;
            display: inline-block;
            .text-overflow;
          }
        }
        .time{
          margin-bottom: 15px;
          font-size: 14px;
          color: @gray;
          line-height: 1.2;
        }
        .desc{
          margin-bottom: 25px;
          line-height: 1.5;
          font-size: 14px;
          color: @light-black;
        }
        .btn{
          border: 1px solid @border;
          color: @gray;
          background-color: #fff;
        }
      }
    }
  }
}

.article-list{
  .legal-business-lecture-list;
  li{
    a{
      .img{
        .img-wrap(230px, 144px);
      }
      .content{
        width: 590px;
      }
    }
  }
}

.page-padding-bottom{
  padding-bottom: @search_list_space !important;
}

.legal-business-panel{

  .content{
    display: flex;
    font-size: 16px;
    color: @light-black;
    flex-grow: 1;
    padding-top: 5px;
    >.text{
      flex-grow: 1;
      margin-right: 35px;
      a{
        display: flex;
        width: 140px;
        margin-top: 35px;
        &:after{
          transform: rotate(-90deg);
        }
      }
    }
    .img-wrapper{
      position: relative;
      margin-right: 10px;
      flex-shrink: 0;
      .img{
        .img-wrap(500px, 310px);
        position: relative;
        z-index: 2;
      }
      &:before{
        content: '';
        position: absolute;
        right: -10px;
        top: -10px;
        width: 404px;
        height: 196px;
        background-color: @main;
      }
    }
  }
}

.course-intro-panel{
  .content{
    padding: 30px 20px;
    .bg(course1,jpg);
    color: #fff;
    display: flex;
    border-radius: 4px;
    overflow: hidden;
    .desc{
      margin-right: 100px;
      line-height: 1.5;
      width: 370px;
      font-size: 16px;
      flex-shrink: 0;
    }
    .course-contact-list{
      font-size: 14px;
      flex-grow: 1;
      line-height: 1.8;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.join-table{
  width: 100%;
  font-size: 16px;
  color: @light-black;
  tbody{
    tr{
      &:hover{
        color: @main;
        td{
          &.opera{
            .opera-btn{
              background-color: @main;
              color: #fff;
            }
          }
        }
      }
      td{
        width: 210px;
        padding: 15px 0;
        &.opera{
          width: 60px;
          .opera-btn{
            height: 26px;
            font-size: 16px;
            width: 60px;
            border-radius: 25px;
            background-color: #fff;
            .flex-center;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.annual-report-list{
  margin-left: -40px;
  margin-bottom: -35px;
  display: flex;
  flex-wrap: wrap;
  li{
    margin-left: 40px;
    width: 182px;
    margin-bottom: 35px;
    &:hover{
      a{
        .img{
          .num{
            transform: translateX(3px);
          }
        }
        .desc{
          color: @main;
        }
      }
    }
    a{
      display: block;
      .img{
        .img-wrap(100%, 214px);
        margin-bottom: 10px;
        position: relative;
        .num{
          position: absolute;
          right: 15px;
          bottom: 35px;
          font-size: 30px;
          color: @main;
          transition: all .3s;
        }
      }
      .desc{
        line-height: 1.5;
        font-size: 14PX;
        padding: 0 10px;
      }
    }
  }
}

.pagination{
  .flex-center;
  font-size: 18px;
  color: @light-black;
  margin-top: 50px;
  .page-nav{
    border-radius: 4px;
    border: 1px solid @border;
    width: 24px;
    height: 24px;
    .flex-center;
    &:last-child{
      img{
        transform: rotateY(180deg);
      }
    }
    &.disabled{
      cursor: default;
    }
  }
  .number{
    padding: 0 5px;
    margin: 0 5px;
    &.on{
      color: @main;
    }
  }
}

.recruit-list{
  font-size: 16px;
  color: @light-black;
  padding-top: 25px;
  line-height: 1.5;
  >li{
    & + li{
      margin-top: 30px;
    }
    a{
      display: flex;
      justify-content: space-between;
      line-height: 1.5;
      cursor: pointer;
    }
    &:hover{
      .desc{
        color: @main;
      }
    }
    .desc{
      padding-right: 20px;
      .text-overflow;
    }
    .time{
      flex-shrink: 0;
    }
  }
}


.no-data,.pagination{
  padding-top: 50px;
}

.no-data{
  font-size: 14px;
  color: @light-black;
}

.service-society-list{
  display: flex;
  align-items: center;
  >li{
    width: 25%;
    &:nth-child(even){
      a{
        flex-direction: column-reverse;
        .content{
          &:before{
            top: unset;
            bottom: -3px;
          }
        }
      }
    }
    a{
      display: flex;
      flex-direction: column;
      color: @main;
      background-color: #ccc8cc;
      transition: all .3s;
      &:hover,&.active{
        color: #fff;
        background-color: @main;
        .content{
          .service-society-title{
            &:after,&:before{
              background-color: #fff;
            }
          }
          &:before{
            background-color: @main;
          }
        }
      }
      .img{
        height: 178px;
      }
      .content{
        height: 178px;
        .flex-center;
        flex-direction: column;
        position: relative;
        &:before{
          content: '';
          position: absolute;
          top: -3px;
          left: 50%;
          margin-left: -9px;
          transition: all .3s;
          width: 18px;
          height: 18px;
          background-color: #ccc8cc;
          transform: rotate(45deg);
        }
        .service-society-title{
          font-size: 20px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 15px;
          line-height: 1.5;
          &:after,&:before{
            content: '';
            transition: all .3s;
            width: 25px;
            background-color: @main;
            height: 1px;
          }
          &:before{
            margin-right: 13px;
          }
          &:after{
            margin-left: 13px;
          }
        }
        .service-society-desc{
          font-size: 14px;
          line-height: 1.5;
        }
      }
    }
  }
}

.tab-list{
  display: flex;
  align-items: center;
  margin-left: -40px;
  margin-bottom: -15px;
  >li{
    margin-bottom: 15px;
    margin-left: 40px;
    &:hover{
      a{
      }
    }
    a{

    }
  }
}
