//所有项目的公共样式
@import "var";

.flex-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-center{
  text-align: center !important;
}

.text-right{
  text-align: right !important;
}

.over-hidden{
  overflow: hidden !important;
}

.margin-center{
  margin-left: auto;
  margin-right: auto;
}

.text-overflow{
  .over-hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-limit(@lineCount){
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: @lineCount;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.text-limit2{
  .text-limit(2);
}
.text-limit3{
  .text-limit(3);
}
.text-limit4{
  .text-limit(4);
}
.text-limit5{
  .text-limit(5);
}

//图片超出裁剪
.img-wrap(@width,@height: auto) {
  width: @width;
  height: @height;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  flex-shrink: 0;
}

//设置圆形图片
.avatar(@size) {
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  width: @size;
  height: @size;
  line-height: @size;
  font-size: 0;
  flex-shrink: 0;
  img {
    border-radius: 50%;
  }
}

.hidden{
  display: none !important;
}

.visibility-hidden{
  visibility: hidden !important;
}

.margin-center{
  margin-left: auto;
  margin-right: auto;
}

strong{
  font-weight: bold;
}

em{
  font-style: italic;
}

i,span{
  display: inline-block;
}

.triangle{
  width: 0;
  height: 0;
  border-top: 50px solid black;
  border-right: 50px solid red;
  border-bottom: 50px solid green;
  border-left: 50px solid blue;
}

.triangle-left{
  .triangle;
  border-top: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 50px solid transparent;
}
.triangle-right{
  .triangle;
  border-top: 50px solid transparent;
  border-left: 50px solid transparent;
  border-bottom: 50px solid transparent;
}
.triangle-top{
  .triangle;
  border-right: 50px solid transparent;
  border-left: 50px solid transparent;
  border-bottom: 50px solid transparent;
}
.triangle-bottom{
  .triangle;
  border-top: 50px solid transparent;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
}

.img-zoom(@img_class: img){
  overflow: hidden;
  @{img_class}{
    transition: transform .3s;
  }
  &:hover{
    @{img_class}{
      transform: scale(1.1);
    }
  }
}

.tab-pane{
  display: none;
  &.active{
    display: block;
  }
}

.img-scale{
  //.img-zoom(img);
  overflow: hidden;
  img{
    transition: transform .3s;
  }
  &:hover{
    img{
      transform: scale(1.1);
    }
  }
}

.mask-wrapper{
  position: relative;
  &:before{
    content: '';
    opacity: 0;
    transition: all .3s;
    background-color: rgba(51, 51, 51, 0.4);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  &:hover{
    &:before{
      opacity: 1;
    }
  }
}

.mb0{
  margin-bottom: 0!important;
}

