@import "var";
@import "tool";

.page{
  display: flex;
  flex-direction: column;
  header,footer{
    flex-shrink: 0;
  }
  main{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .main-panel{
      flex-grow: 1;
      position: relative;
      z-index: 3;
    }
  }
}

.text-blue{
  color: @blue !important;
}

.text-main{
  color: @main !important;
}

.btn{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  background-color: @main;
  color: #fff;
  padding: 0 20px;

  .btn-color(@bg-color,@border-color: @bg-color,@color: @bg-color){
    background-color: @bg-color;
    color: #fff;
    border: 1px solid @border-color;
    &.btn-border{
      color: @color;
      background-color: #fff;
      border: 1px solid @color;
      &:hover{
        background-color: @border-color;
        color: #fff;
      }
    }
  }

  &.btn-white{
    .btn-color(#fff, #fff, @main);
    color: @main;
  }

  &.btn-square{
    border-radius: 0 !important;
  }

  &.btn-radius{
    border-radius: 25px !important;
  }

  &.btn-block{
    display: flex;
    width: 100%;
  }

}

.map{
  img{
    max-width: unset;
    max-height: unset;
  }
}

.events-swiper{
  position: relative;
  .swiper-prev,.swiper-next{
    position: absolute;
    top: 50%;
    margin-top: -11px;
    border: 1px solid @border;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    .flex-center;
    cursor: pointer;
    &.swiper-button-disabled{
      opacity: .5;
      cursor: default;
    }
  }
  .swiper-prev{
    left: 10px;
  }
  .swiper-next{
    right: 10px;
    transform: rotateY(180deg);
  }
  .swiper-container{
    width: 760px;
    height: 60px;
    .margin-center;
    margin-bottom: 35px;
    .swiper-slide{
      text-align: center;
      border-bottom: 1px solid @main;
      position: relative;
      padding-top: 15px;
      cursor: pointer;

      &.active,&:hover{
        .year{
          transform: translateY(-3px) rotate(-45deg);
          color: @main;
        }
      }
      &:first-child,&:last-child{
        border-bottom: 0;
        &:after{
          content: '';
          position: absolute;
          width: 50%;
          bottom: 0;
          border-bottom: 1px solid @main;
        }
      }
      &:first-child{
        &:after{
          left: 50%;
        }
      }
      &:last-child{
        &:after{
          left: 0;
        }
      }
      .year{
        transition: all .3s;
        color: @light-black;
        transform: rotate(-45deg);
      }
      &:before{
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        height: 13px;
        border-left: 1px solid @main;
      }
    }
  }
}

.events-wrapper{
  .year{
    position: relative;
    z-index: 2;
    border-radius: 50%;
    color: #fff;
    font-size: 14px;
    .margin-center;
    background-color: #dbb6da;
    width: 55px;
    height: 55px;
    .flex-center;
    .text{
      background-color: @main;
      width: 45px;
      height: 45px;
      text-align: center;
      border-radius: 50%;
      line-height: 45px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.tab-list{
  display: flex;
  align-items: center;
  >li{
    &:hover,&.active{
      a{
        color: @main;
        border-color: @main;
        background-color: #fff;
      }
    }
    a{
      background-color: @bg-gray;
      border: 1px solid @border;
      color: @black;
    }
  }
}






