.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.over-hidden {
  overflow: hidden !important;
}
.margin-center {
  margin-left: auto;
  margin-right: auto;
}
.text-overflow {
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-limit2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.text-limit3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.text-limit4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.text-limit5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.hidden {
  display: none !important;
}
.visibility-hidden {
  visibility: hidden !important;
}
.margin-center {
  margin-left: auto;
  margin-right: auto;
}
strong {
  font-weight: bold;
}
em {
  font-style: italic;
}
i,
span {
  display: inline-block;
}
.triangle {
  width: 0;
  height: 0;
  border-top: 50px solid black;
  border-right: 50px solid red;
  border-bottom: 50px solid green;
  border-left: 50px solid blue;
}
.triangle-left {
  width: 0;
  height: 0;
  border-top: 50px solid black;
  border-right: 50px solid red;
  border-bottom: 50px solid green;
  border-left: 50px solid blue;
  border-top: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 50px solid transparent;
}
.triangle-right {
  width: 0;
  height: 0;
  border-top: 50px solid black;
  border-right: 50px solid red;
  border-bottom: 50px solid green;
  border-left: 50px solid blue;
  border-top: 50px solid transparent;
  border-left: 50px solid transparent;
  border-bottom: 50px solid transparent;
}
.triangle-top {
  width: 0;
  height: 0;
  border-top: 50px solid black;
  border-right: 50px solid red;
  border-bottom: 50px solid green;
  border-left: 50px solid blue;
  border-right: 50px solid transparent;
  border-left: 50px solid transparent;
  border-bottom: 50px solid transparent;
}
.triangle-bottom {
  width: 0;
  height: 0;
  border-top: 50px solid black;
  border-right: 50px solid red;
  border-bottom: 50px solid green;
  border-left: 50px solid blue;
  border-top: 50px solid transparent;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
}
.tab-pane {
  display: none;
}
.tab-pane.active {
  display: block;
}
.img-scale {
  overflow: hidden;
}
.img-scale img {
  transition: transform 0.3s;
}
.img-scale:hover img {
  transform: scale(1.1);
}
.mask-wrapper {
  position: relative;
}
.mask-wrapper:before {
  content: '';
  opacity: 0;
  transition: all 0.3s;
  background-color: rgba(51, 51, 51, 0.4);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.mask-wrapper:hover:before {
  opacity: 1;
}
.mb0 {
  margin-bottom: 0!important;
}
.page {
  display: flex;
  flex-direction: column;
}
.page header,
.page footer {
  flex-shrink: 0;
}
.page main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.page main .main-panel {
  flex-grow: 1;
  position: relative;
  z-index: 3;
}
.text-blue {
  color: #288bf0 !important;
}
.text-main {
  color: #890c84 !important;
}
.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  background-color: #890c84;
  color: #fff;
  padding: 0 20px;
}
.btn.btn-white {
  background-color: #fff;
  color: #fff;
  border: 1px solid #fff;
  color: #890c84;
}
.btn.btn-white.btn-border {
  color: #890c84;
  background-color: #fff;
  border: 1px solid #890c84;
}
.btn.btn-white.btn-border:hover {
  background-color: #fff;
  color: #fff;
}
.btn.btn-square {
  border-radius: 0 !important;
}
.btn.btn-radius {
  border-radius: 25px !important;
}
.btn.btn-block {
  display: flex;
  width: 100%;
}
.map img {
  max-width: unset;
  max-height: unset;
}
.events-swiper {
  position: relative;
}
.events-swiper .swiper-prev,
.events-swiper .swiper-next {
  position: absolute;
  top: 50%;
  margin-top: -11px;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.events-swiper .swiper-prev.swiper-button-disabled,
.events-swiper .swiper-next.swiper-button-disabled {
  opacity: 0.5;
  cursor: default;
}
.events-swiper .swiper-prev {
  left: 10px;
}
.events-swiper .swiper-next {
  right: 10px;
  transform: rotateY(180deg);
}
.events-swiper .swiper-container {
  width: 760px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 35px;
}
.events-swiper .swiper-container .swiper-slide {
  text-align: center;
  border-bottom: 1px solid #890c84;
  position: relative;
  padding-top: 15px;
  cursor: pointer;
}
.events-swiper .swiper-container .swiper-slide.active .year,
.events-swiper .swiper-container .swiper-slide:hover .year {
  transform: translateY(-3px) rotate(-45deg);
  color: #890c84;
}
.events-swiper .swiper-container .swiper-slide:first-child,
.events-swiper .swiper-container .swiper-slide:last-child {
  border-bottom: 0;
}
.events-swiper .swiper-container .swiper-slide:first-child:after,
.events-swiper .swiper-container .swiper-slide:last-child:after {
  content: '';
  position: absolute;
  width: 50%;
  bottom: 0;
  border-bottom: 1px solid #890c84;
}
.events-swiper .swiper-container .swiper-slide:first-child:after {
  left: 50%;
}
.events-swiper .swiper-container .swiper-slide:last-child:after {
  left: 0;
}
.events-swiper .swiper-container .swiper-slide .year {
  transition: all 0.3s;
  color: #666;
  transform: rotate(-45deg);
}
.events-swiper .swiper-container .swiper-slide:before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  height: 13px;
  border-left: 1px solid #890c84;
}
.events-wrapper .year {
  position: relative;
  z-index: 2;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  margin-left: auto;
  margin-right: auto;
  background-color: #dbb6da;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.events-wrapper .year .text {
  background-color: #890c84;
  width: 45px;
  height: 45px;
  text-align: center;
  border-radius: 50%;
  line-height: 45px;
  display: inline-block;
  vertical-align: middle;
}
.tab-list {
  display: flex;
  align-items: center;
}
.tab-list > li:hover a,
.tab-list > li.active a {
  color: #890c84;
  border-color: #890c84;
  background-color: #fff;
}
.tab-list > li a {
  background-color: #f7f7f7;
  border: 1px solid #e5e5e5;
  color: #333;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  word-break: break-all;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1.2;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body {
  font: 16px/1.4286 arial, "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
  color: #333;
  background-color: #fff;
}
a {
  text-decoration: none;
  cursor: pointer;
}
p {
  line-height: 1.5;
}
img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  vertical-align: middle;
}
/* 普通文章样式 */
.article-content {
  padding-top: 30px;
}
.article-content span {
  display: inline;
}
.article-content table {
  border-collapse: collapse;
  width: auto;
  word-break: break-all;
}
.article-content table,
.article-content td,
.article-content th {
  border: 1px solid #666;
}
.article-content em,
.article-content i {
  font-style: italic;
}
.article-content strong {
  font-weight: bold;
}
.article-content p,
.article-content pre {
  font-size: 14px;
  color: #333;
  line-height: 1.5;
  margin-bottom: 20px;
  text-align: left;
}
.article-content p:last-child {
  margin-bottom: 0;
}
.article-content img {
  max-width: 100%!important;
  height: auto!important;
}
.article-content h1 {
  font-size: 20px;
}
.article-content h2 {
  font-size: 18px;
}
.article-content h3 {
  font-size: 16px;
}
.article-content h4,
.article-content h5,
.article-content h6 {
  font-size: 14px;
}
.article-content h1,
.article-content h2,
.article-content h3,
.article-content h4,
.article-content h5,
.article-content h6 {
  padding: 15px 0;
  font-weight: bold;
}
.article-content ul {
  list-style: square;
  margin-left: 1em;
  padding: 10px 0;
}
.article-content a {
  color: #0b76ac;
  text-decoration: underline;
}
.article-content ol {
  list-style: decimal;
  margin-left: 1em;
  padding: 10px 0;
}
.article-content blockquote {
  padding-left: 10px;
  border-left: 3px solid #dbdbdb;
  font-size: 14px;
}
.article-content blockquote p {
  font-size: 14px;
}
* {
  -webkit-text-size-adjust: none;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
}
html {
  min-height: 100%;
  height: 100%;
}
html body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
html body .page {
  flex-grow: 1;
}
input,
button,
textarea,
select {
  outline: none;
  border: 0;
}
input:focus,
button:focus,
textarea:focus,
select:focus {
  outline: none;
}
a {
  color: unset;
}
button {
  border: 0;
}
.mb40 {
  margin-bottom: 40px !important;
}
.btn.btn-s {
  font-size: 14px;
  width: 80px;
  height: 26px;
  line-height: 26px;
  padding: 0;
}
.btn.btn-m {
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  padding: 0 15px;
}
.container {
  width: 1180px;
  margin-left: auto;
  margin-right: auto;
}
.container-fluid {
  min-width: 1180px;
}
.banner {
  max-height: 320px;
  overflow: hidden;
  margin-bottom: -60px;
  position: relative;
}
.banner:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 1;
}
.banner .title {
  position: absolute;
  z-index: 2;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  font-size: 18px;
  line-height: 1.5;
}
.panel-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.panel-title > .title {
  font-size: 24px;
  color: #890c84;
  display: flex;
  align-items: baseline;
}
.panel-title > .title .sub-title {
  font-size: 14px;
  color: #666;
  margin-left: 5px;
}
.panel-title .more {
  font-size: 14px;
  color: #999;
}
.panel {
  width: 1180px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
  position: relative;
  z-index: 1;
}
.panel + .panel {
  margin-top: 20px;
}
.panel .panel-title-wrapper {
  display: flex;
  align-items: baseline;
}
.panel .panel-title-wrapper.panel-title-wrapper__border-bottom {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 25px;
}
.panel .panel-title {
  height: 65px;
  padding-top: 30px;
  padding-bottom: 15px;
}
.panel .panel-title-bar {
  flex-shrink: 0;
  font-size: 24px;
  color: #333;
  line-height: 1.5;
  padding-top: 25px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.panel .panel-title-deco {
  font-size: 24px;
  color: #333;
  position: relative;
  padding-left: 10px;
}
.panel .panel-title-deco:before {
  content: '';
  position: absolute;
  left: 0;
  top: 14%;
  width: 5px;
  height: 76%;
  background-color: #890c84;
}
.panel .panel-content {
  margin-top: 5px;
}
.subject-wrapper {
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}
.subject-wrapper .subject-list {
  width: 230px;
  flex-shrink: 0;
  margin-right: 60px;
  border-top: 1px solid #e5e5e5;
}
.subject-wrapper .subject-list > li {
  height: 80px;
  font-size: 18px;
  border-bottom: 1px solid #e5e5e5;
  transition: all 0.3s;
}
.subject-wrapper .subject-list > li a {
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333;
  height: 100%;
  transition: all 0.3s;
}
.subject-wrapper .subject-list > li:hover,
.subject-wrapper .subject-list > li.active {
  background-color: #890c84;
}
.subject-wrapper .subject-list > li:hover a,
.subject-wrapper .subject-list > li.active a {
  color: #fff;
}
.subject-wrapper .subject-list > li:hover a img,
.subject-wrapper .subject-list > li.active a img {
  filter: brightness(5);
}
.subject-wrapper .subject-content {
  width: 850px;
}
.subject-wrapper .subject-content .subject-title {
  font-size: 24px;
  color: #333;
  display: flex;
  align-items: baseline;
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e5e5;
}
.subject-wrapper .subject-content .subject-title .sub-title {
  font-size: 14px;
  color: #999;
  display: inline-block;
  margin-left: 5px;
}
.subject-wrapper .subject-content .recruit-list {
  padding-top: 0;
}
.icon-close {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAA10lEQVQ4jZ3VvXHDIBgG4Me+NBpAU8S1+3iOpNUKyQZuvYTXcPq49hJKH5VJIxyOwwL0dXDHc/y8wGYYBnN1mKyr+9jt3PGBL/QrsB5XvAewwyt2uDSiPT7xjDd023mqB9wa0Ri7zcYUljw2ojls5H8PW9AUewlYCtagOew7BlJwCS1i8JQBY/QSoZsStgTmUCWM/JLj+i20m8B0z6oi9QjMHUBVTnPgo9OsymkKlqJRRGOwKmclNIC1WBENz1cLtoTen69zI5ZDz5jCTTniZN0XMGKPH/gDbrtjH7Keh4YAAAAASUVORK5CYII=) no-repeat center;
  background-size: cover;
  width: 20px;
  height: 20px;
}
.popup-wrapper {
  border-radius: 0;
  padding: 0 35px 35px;
}
.popup-wrapper .popup-header {
  padding: 25px 0;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
}
.popup-wrapper .popup-header h4.title {
  font-size: 18px;
  color: #333;
  line-height: 1.5;
  padding-right: 40px;
}
.popup-wrapper .popup-header h4.title span {
  display: inline;
}
.popup-wrapper .popup-header .icon-close {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -10px;
}
.popup-wrapper .popup-content {
  padding-left: 0;
  padding-right: 0;
}
.bread-nav {
  display: flex;
  align-items: center;
  color: #999;
  font-size: 14px;
  padding: 20px 0;
}
.bread-nav > li {
  display: inline-flex;
}
.bread-nav > li + li:before {
  content: '>';
  display: inline-flex;
  padding-left: 5px;
  padding-right: 5px;
}
.load-more {
  font-size: 16px;
  color: #890c84;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.load-more.active:after {
  transform: rotate(-180deg);
}
.load-more:after {
  content: '';
  display: inline-block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAAfElEQVQYlX3OwQkCQQwF0DeDIIKduHVsLaJoFV68eRDXMuzDkyBiLV7WSwZkGDcQcsh/JOm4PECHp+la4YUxY40H9hOgxx1npIwUffoDe9ywiJyMAZsI1PAXDNhinMXyGvMSEN4tAAW14AfzGpT3VLC82gT1pfpih10N4Av1pR3FS6ZIpwAAAABJRU5ErkJggg==) no-repeat center;
  background-size: cover;
  width: 13px;
  height: 8px;
  margin-left: 10px;
  transition: all 0.3s;
}
.member-list {
  margin-left: -40px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -30px;
}
.member-list.member-list-3 {
  margin-left: -30px;
  margin-bottom: -30px;
}
.member-list.member-list-3 > li {
  width: 360px;
  margin-left: 30px;
  margin-bottom: 30px;
}
.member-list > li {
  flex-shrink: 0;
  margin-bottom: 30px;
  height: 160px;
  padding: 20px 25px;
  border: 1px solid #e5e5e5;
  width: 405px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 40px;
  background-color: #f7f7f7;
  transition: all 0.3s;
}
.member-list > li:hover {
  background-color: #fff;
  border-color: transparent;
  box-shadow: 0 0 11px 3px rgba(0, 0, 0, 0.1);
}
.member-list > li:hover .content .member-name-wrapper {
  color: #890c84;
}
.member-list > li:hover .content .member-name-wrapper .rank {
  color: #890c84;
}
.member-list > li .img {
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  width: 120px;
  height: 120px;
  line-height: 120px;
  font-size: 0;
  flex-shrink: 0;
  margin-right: 20px;
}
.member-list > li .img img {
  border-radius: 50%;
}
.member-list > li .content {
  padding-top: 20px;
  flex-grow: 1;
}
.member-list > li .content .rank {
  font-size: 14px;
  display: inline-block;
  margin-left: 10px;
  color: #999;
}
.member-list > li .content .btn {
  align-items: baseline;
  height: 30px;
  line-height: 30px;
  padding: 0 15px;
  margin-bottom: 15px;
  font-size: 18px;
  cursor: default;
}
.member-list > li .content .btn .rank {
  color: #fff;
}
.member-list > li .content .member-name-wrapper {
  font-size: 18px;
  color: #333;
  line-height: 1.5;
  display: flex;
  margin-bottom: 15px;
  align-items: baseline;
}
.member-list > li .content .title {
  font-size: 18px;
  color: #333;
  line-height: 1.5;
  display: flex;
  margin-bottom: 15px;
  align-items: baseline;
}
.member-list > li .content .title .rank {
  font-size: 14px;
  display: inline-block;
  margin-left: 10px;
  color: #999;
}
.member-list > li .content .desc {
  font-size: 14px;
  color: #666;
  line-height: 1.2;
}
.mini-page {
  font-size: 14px;
  display: flex;
  align-items: center;
}
.mini-page .mini-page-item {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #890c84;
  display: inline-flex;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  cursor: pointer;
  padding-left: 1px;
}
.mini-page .mini-page-item + .mini-page-item {
  margin-left: 10px;
}
.mini-page .mini-page-item.active,
.mini-page .mini-page-item:hover {
  border-color: #890c84;
  color: #fff;
  background-color: #890c84;
}
.multi-col-text {
  line-height: 1.8;
  color: #666;
  font-size: 16px;
}
.legal-business-lecture-list > li {
  padding-top: 30px;
  overflow: hidden;
}
.legal-business-lecture-list > li img {
  transition: transform 0.3s;
}
.legal-business-lecture-list > li:hover img {
  transform: scale(1.1);
}
.legal-business-lecture-list > li:hover a .content .title {
  color: #890c84;
}
.legal-business-lecture-list > li:hover a .content .btn {
  border-color: #890c84;
  color: #fff;
  background-color: #890c84;
}
.legal-business-lecture-list > li a {
  display: flex;
}
.legal-business-lecture-list > li a .img {
  width: 320px;
  height: 200px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  flex-shrink: 0;
  margin-right: 40px;
}
.legal-business-lecture-list > li a .content {
  border-bottom: 1px solid #e5e5e5;
  width: 770px;
}
.legal-business-lecture-list > li a .content .title {
  font-size: 18px;
  color: #333;
  line-height: 1.5;
  margin-bottom: 10px;
  display: flex;
}
.legal-business-lecture-list > li a .content .title span {
  width: 100%;
  display: inline-block;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.legal-business-lecture-list > li a .content .time {
  margin-bottom: 15px;
  font-size: 14px;
  color: #999;
  line-height: 1.2;
}
.legal-business-lecture-list > li a .content .desc {
  margin-bottom: 25px;
  line-height: 1.5;
  font-size: 14px;
  color: #666;
}
.legal-business-lecture-list > li a .content .btn {
  border: 1px solid #e5e5e5;
  color: #999;
  background-color: #fff;
}
.article-list > li {
  padding-top: 30px;
  overflow: hidden;
}
.article-list > li img {
  transition: transform 0.3s;
}
.article-list > li:hover img {
  transform: scale(1.1);
}
.article-list > li:hover a .content .title {
  color: #890c84;
}
.article-list > li:hover a .content .btn {
  border-color: #890c84;
  color: #fff;
  background-color: #890c84;
}
.article-list > li a {
  display: flex;
}
.article-list > li a .img {
  width: 320px;
  height: 200px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  flex-shrink: 0;
  margin-right: 40px;
}
.article-list > li a .content {
  border-bottom: 1px solid #e5e5e5;
  width: 770px;
}
.article-list > li a .content .title {
  font-size: 18px;
  color: #333;
  line-height: 1.5;
  margin-bottom: 10px;
  display: flex;
}
.article-list > li a .content .title span {
  width: 100%;
  display: inline-block;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.article-list > li a .content .time {
  margin-bottom: 15px;
  font-size: 14px;
  color: #999;
  line-height: 1.2;
}
.article-list > li a .content .desc {
  margin-bottom: 25px;
  line-height: 1.5;
  font-size: 14px;
  color: #666;
}
.article-list > li a .content .btn {
  border: 1px solid #e5e5e5;
  color: #999;
  background-color: #fff;
}
.article-list li a .img {
  width: 230px;
  height: 144px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  flex-shrink: 0;
}
.article-list li a .content {
  width: 590px;
}
.page-padding-bottom {
  padding-bottom: 70px !important;
}
.legal-business-panel .content {
  display: flex;
  font-size: 16px;
  color: #666;
  flex-grow: 1;
  padding-top: 5px;
}
.legal-business-panel .content > .text {
  flex-grow: 1;
  margin-right: 35px;
}
.legal-business-panel .content > .text a {
  display: flex;
  width: 140px;
  margin-top: 35px;
}
.legal-business-panel .content > .text a:after {
  transform: rotate(-90deg);
}
.legal-business-panel .content .img-wrapper {
  position: relative;
  margin-right: 10px;
  flex-shrink: 0;
}
.legal-business-panel .content .img-wrapper .img {
  width: 500px;
  height: 310px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  flex-shrink: 0;
  position: relative;
  z-index: 2;
}
.legal-business-panel .content .img-wrapper:before {
  content: '';
  position: absolute;
  right: -10px;
  top: -10px;
  width: 404px;
  height: 196px;
  background-color: #890c84;
}
.course-intro-panel .content {
  padding: 30px 20px;
  background: url('../../../../img/pc/course1.jpg') no-repeat center;
  background-size: cover;
  color: #fff;
  display: flex;
  border-radius: 4px;
  overflow: hidden;
}
.course-intro-panel .content .desc {
  margin-right: 100px;
  line-height: 1.5;
  width: 370px;
  font-size: 16px;
  flex-shrink: 0;
}
.course-intro-panel .content .course-contact-list {
  font-size: 14px;
  flex-grow: 1;
  line-height: 1.8;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}
.join-table {
  width: 100%;
  font-size: 16px;
  color: #666;
}
.join-table tbody tr:hover {
  color: #890c84;
}
.join-table tbody tr:hover td.opera .opera-btn {
  background-color: #890c84;
  color: #fff;
}
.join-table tbody tr td {
  width: 210px;
  padding: 15px 0;
}
.join-table tbody tr td.opera {
  width: 60px;
}
.join-table tbody tr td.opera .opera-btn {
  height: 26px;
  font-size: 16px;
  width: 60px;
  border-radius: 25px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.annual-report-list {
  margin-left: -40px;
  margin-bottom: -35px;
  display: flex;
  flex-wrap: wrap;
}
.annual-report-list li {
  margin-left: 40px;
  width: 182px;
  margin-bottom: 35px;
}
.annual-report-list li:hover a .img .num {
  transform: translateX(3px);
}
.annual-report-list li:hover a .desc {
  color: #890c84;
}
.annual-report-list li a {
  display: block;
}
.annual-report-list li a .img {
  width: 100%;
  height: 214px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  flex-shrink: 0;
  margin-bottom: 10px;
  position: relative;
}
.annual-report-list li a .img .num {
  position: absolute;
  right: 15px;
  bottom: 35px;
  font-size: 30px;
  color: #890c84;
  transition: all 0.3s;
}
.annual-report-list li a .desc {
  line-height: 1.5;
  font-size: 14PX;
  padding: 0 10px;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #666;
  margin-top: 50px;
}
.pagination .page-nav {
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination .page-nav:last-child img {
  transform: rotateY(180deg);
}
.pagination .page-nav.disabled {
  cursor: default;
}
.pagination .number {
  padding: 0 5px;
  margin: 0 5px;
}
.pagination .number.on {
  color: #890c84;
}
.recruit-list {
  font-size: 16px;
  color: #666;
  padding-top: 25px;
  line-height: 1.5;
}
.recruit-list > li + li {
  margin-top: 30px;
}
.recruit-list > li a {
  display: flex;
  justify-content: space-between;
  line-height: 1.5;
  cursor: pointer;
}
.recruit-list > li:hover .desc {
  color: #890c84;
}
.recruit-list > li .desc {
  padding-right: 20px;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.recruit-list > li .time {
  flex-shrink: 0;
}
.no-data,
.pagination {
  padding-top: 50px;
}
.no-data {
  font-size: 14px;
  color: #666;
}
.service-society-list {
  display: flex;
  align-items: center;
}
.service-society-list > li {
  width: 25%;
}
.service-society-list > li:nth-child(even) a {
  flex-direction: column-reverse;
}
.service-society-list > li:nth-child(even) a .content:before {
  top: unset;
  bottom: -3px;
}
.service-society-list > li a {
  display: flex;
  flex-direction: column;
  color: #890c84;
  background-color: #ccc8cc;
  transition: all 0.3s;
}
.service-society-list > li a:hover,
.service-society-list > li a.active {
  color: #fff;
  background-color: #890c84;
}
.service-society-list > li a:hover .content .service-society-title:after,
.service-society-list > li a.active .content .service-society-title:after,
.service-society-list > li a:hover .content .service-society-title:before,
.service-society-list > li a.active .content .service-society-title:before {
  background-color: #fff;
}
.service-society-list > li a:hover .content:before,
.service-society-list > li a.active .content:before {
  background-color: #890c84;
}
.service-society-list > li a .img {
  height: 178px;
}
.service-society-list > li a .content {
  height: 178px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.service-society-list > li a .content:before {
  content: '';
  position: absolute;
  top: -3px;
  left: 50%;
  margin-left: -9px;
  transition: all 0.3s;
  width: 18px;
  height: 18px;
  background-color: #ccc8cc;
  transform: rotate(45deg);
}
.service-society-list > li a .content .service-society-title {
  font-size: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  line-height: 1.5;
}
.service-society-list > li a .content .service-society-title:after,
.service-society-list > li a .content .service-society-title:before {
  content: '';
  transition: all 0.3s;
  width: 25px;
  background-color: #890c84;
  height: 1px;
}
.service-society-list > li a .content .service-society-title:before {
  margin-right: 13px;
}
.service-society-list > li a .content .service-society-title:after {
  margin-left: 13px;
}
.service-society-list > li a .content .service-society-desc {
  font-size: 14px;
  line-height: 1.5;
}
.tab-list {
  display: flex;
  align-items: center;
  margin-left: -40px;
  margin-bottom: -15px;
}
.tab-list > li {
  margin-bottom: 15px;
  margin-left: 40px;
}
.icon-loading {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAACeUlEQVRIS8WVTWgTQRTH39tNK1i/D15VPHnw5EH0IC1kU4OtoKWCCBLNJkJzquDBsJtsNyl400sLJhMiWBBajIeCbbIr9aaIJwXxIOJJhILoqViTeWW22WRrk2abHNzT7Lz3/r+Z997MIOziiz+wDvI/fJKlhw2/YejX8bZZiUiIRdd//8DA3od3z691ivcNUDPWcwC42hBEZEwLxnYNmDDm963Lh3OA8I5pyiNXQM1WkkA43QTApNfeDrRtB2rGItcZARfyevCa+I9PW5dqhAkkCnvnXd9otjIuxgUttOCFbQGoU2UDJCntdWC64iuNnoUNMV153VykR83pkr/wy2N8mteVm53yLOzRrF1EoHWmKXfa7kAY6t0ySgQ/EOEV05WSH4DvGvQi1irWV357gTqAzeLKI2KMRCv5lHKvF9GIsXT8iRH+5ug5BcpYvxHgQF10rSbvOVZMXljtBhLL2CMEtChiRQdiPPPyJIe+L14xqcZP5Yzhz90AnG4iiojYaq16YjNFpl0CpCt1wbdMV851Iy5iRHoCsjwGCN+ZFnrWKPIts3w2QNKhfFopdyv+/7roX7Jq2nEJ6E0upXzsdTc7XnZEMFtIKYlOEDVbuY4kOTUk5C9E7j3XTTM8lrVvENFcc4ZWmR462hGw9a0oMV0ZawmIm9ZpjvChIYi4yLTgZfdfNctngGCU+vvnCveHGq0dy9oTRDTjHCzERF4LzrYEOIfOtGYQaRwBvxLny+77q2asQQBYcQO913jUWD4iBQJhYePV6lLBuPizLaBdOra9FZxP+Xn8fV92TnpQet9qBzvVyDfAOfEiTZwPyn3y/ONk8FOn4gv7BmVw5NCuuxYDAAAAAElFTkSuQmCC) no-repeat center;
  width: 24px;
  height: 24px;
  background-origin: content-box;
  background-size: contain;
  display: inline-block;
}
header {
  background-color: #890c84;
  height: 80px;
  position: relative;
  z-index: 6;
}
header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}
header .logo {
  display: flex;
  align-items: center;
  overflow: hidden;
}
header .logo img {
  max-height: 65px;
  max-width: 250px;
}
header .nav-area {
  display: flex;
  align-items: center;
}
header .nav-area .search {
  margin-left: 30px;
  width: 130px;
  height: 30px;
  line-height: 30px;
  color: #666;
  font-size: 16px;
  position: relative;
  border-radius: 25px;
  overflow: hidden;
}
header .nav-area .search input {
  height: 100%;
  background-color: #fff;
  padding-left: 10px;
  padding-right: 30px;
  width: 100%;
  display: block;
}
header .nav-area .search .search-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 100%;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
header .nav {
  display: flex;
  align-items: center;
  font-size: 16px;
}
header .nav .nav-item {
  position: relative;
}
header .nav .nav-item > a {
  height: 80px;
  display: flex;
  align-items: center;
  color: #fff;
  padding: 0 18px;
  transition: all 0.3s;
}
header .nav .nav-item:hover,
header .nav .nav-item.active {
  background-color: #a0139a;
}
header .nav .nav-item .sub-nav {
  position: absolute;
  left: 0;
  top: 80px;
  background-color: rgba(255, 255, 255, 0.9);
  width: 165px;
  display: none;
  box-shadow: 0 2px 8px rgba(41, 41, 41, 0.1);
}
header .nav .nav-item .sub-nav .sub-nav-item {
  display: flex;
  align-items: center;
  height: 50px;
}
header .nav .nav-item .sub-nav .sub-nav-item.active a,
header .nav .nav-item .sub-nav .sub-nav-item:hover a {
  color: #890c84;
}
header .nav .nav-item .sub-nav .sub-nav-item.active a:before,
header .nav .nav-item .sub-nav .sub-nav-item:hover a:before {
  transform: scale(1);
}
header .nav .nav-item .sub-nav .sub-nav-item a {
  color: #333;
  width: 100%;
  height: 100%;
  margin-left: 18px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  position: relative;
}
header .nav .nav-item .sub-nav .sub-nav-item a:before {
  content: '';
  position: absolute;
  left: 0;
  top: 23px;
  width: 4px;
  height: 4px;
  display: block;
  transition: all 0.3s;
  border-radius: 50%;
  background-color: #890c84;
  transform: scale(0);
}
main {
  background-color: #faf7f7;
  padding-bottom: 90px;
}
footer {
  background-color: #e6e6e6;
}
footer .top-box {
  display: flex;
  justify-content: space-between;
  padding: 25px 0;
}
footer .top-box .footer-list {
  display: flex;
}
footer .top-box .footer-list > li {
  width: 135px;
}
footer .top-box .footer-list > li .title {
  font-weight: bold;
  color: #890c84;
  font-size: 16px;
  line-height: 1.5;
}
footer .top-box .footer-list > li .title:hover {
  font-weight: bold;
}
footer .top-box .footer-list > li .item,
footer .top-box .footer-list > li .desc {
  line-height: 1.2;
  margin-top: 15px;
  display: block;
  font-size: 12px;
  color: #666;
}
footer .top-box .footer-list > li .item:hover {
  color: #333;
}
footer .top-box .footer-list > li.concat {
  width: 275px;
  padding-right: 20px;
}
footer .top-box .code {
  text-align: center;
}
footer .top-box .code .img {
  width: 85px;
  height: auto;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  flex-shrink: 0;
  background-color: #fff;
}
footer .top-box .code .desc {
  font-size: 12px;
  color: #666;
  line-height: 1.5;
  margin-top: 10px;
}
footer .bottom-box {
  background-color: #890c84;
  font-size: 14px;
  color: #fff;
}
footer .bottom-box a {
  color: #fff;
}
footer .bottom-box > .container {
  padding: 5px 0;
}
footer .bottom-box > .container .top {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: -30px;
  line-height: 1.5;
}
footer .bottom-box > .container .top .title,
footer .bottom-box > .container .top a {
  display: inline-block;
}
footer .bottom-box > .container .top .title {
  margin-right: 5px;
}
footer .bottom-box > .container .top a {
  margin-right: 30px;
}
footer .bottom-box > .container .bottom {
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
footer .bottom-box > .container .bottom .bottom-left {
  display: flex;
  align-items: center;
}
.right-info {
  position: fixed;
  right: 0;
  top: 45%;
  z-index: 3;
}
.right-info .list > li.minip-code-wrapper {
  position: relative;
}
.right-info .list > li.minip-code-wrapper .code {
  position: absolute;
  left: -140px;
  top: 0;
  width: 130px;
  height: 130px;
  background-color: #fff;
  display: none;
  text-align: center;
  line-height: 130px;
  box-shadow: 0 0 9px 0 rgba(29, 2, 28, 0.2);
}
.right-info .list > li.minip-code-wrapper .code:before {
  content: '';
  position: absolute;
  right: -54px;
  top: 15px;
  width: 0;
  height: 0;
  border-top: 50px solid black;
  border-right: 50px solid red;
  border-bottom: 50px solid green;
  border-left: 50px solid blue;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 5px solid #fff;
  border-top: 9px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 9px solid transparent;
}
.right-info .list > li a {
  position: relative;
  z-index: 1;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 0;
  width: 45px;
  height: 45px;
  background-color: #fff;
  color: #333;
  font-size: 14px;
}
.right-info .list > li a:hover {
  color: #890c84;
}
.right-info .list > li a.active {
  background-color: #890c84;
  color: #fff;
}
.right-info .list > li + li {
  margin-top: 1px;
}
.page-index .banner {
  margin-bottom: -65px;
  max-height: 540px;
}
.page-index .banner:after {
  display: none;
}
.page-index .banner .swiper-container {
  max-height: 540px;
}
.page-index .banner .swiper-button-prev,
.page-index .banner .swiper-button-next {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: none;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  margin-top: -18px;
}
.page-index .banner .swiper-button-prev:hover,
.page-index .banner .swiper-button-next:hover {
  background-color: rgba(255, 255, 255, 0.8);
}
.page-index .banner .swiper-button-prev:hover img,
.page-index .banner .swiper-button-next:hover img {
  transform: translateX(-2px);
}
.page-index .banner .swiper-button-prev img,
.page-index .banner .swiper-button-next img {
  transition: all 0.3s;
  width: 11px;
  height: 19px;
}
.page-index .banner .swiper-button-prev {
  left: 5%;
}
.page-index .banner .swiper-button-next {
  right: 5%;
  transform: rotateY(180deg);
}
.page-index .panel {
  position: relative;
  z-index: 3;
}
.page-index .news-panel .panel-content {
  margin-top: 5px;
  display: flex;
}
.page-index .news-panel .panel-content .news-swiper {
  width: 680px;
  height: 424px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  flex-shrink: 0;
}
.page-index .news-panel .panel-content .news-swiper .swiper-pagination {
  right: 20px;
  bottom: 15px;
  left: unset;
  width: auto;
}
.page-index .news-panel .panel-content .news-swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #890c84;
}
.page-index .news-panel .panel-content .news-panel-content {
  margin-left: 30px;
  width: 430px;
}
.page-index .news-panel .panel-content .news-panel-content > li {
  height: 90px;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
  padding: 20px 0;
}
.page-index .news-panel .panel-content .news-panel-content > li:first-child {
  height: 65px;
  padding-top: 0;
}
.page-index .news-panel .panel-content .news-panel-content > li:hover .title,
.page-index .news-panel .panel-content .news-panel-content > li.active .title {
  color: #890c84;
}
.page-index .news-panel .panel-content .news-panel-content > li .title {
  font-size: 18px;
  color: #333;
  line-height: 1.5;
}
.page-index .news-panel .panel-content .news-panel-content > li .time {
  font-size: 14px;
  color: #999;
  position: absolute;
  right: 0;
  bottom: 12px;
}
.page-index .report-panel .report-list {
  display: flex;
  align-items: center;
  margin-left: -30px;
}
.page-index .report-panel .report-list > li {
  margin-left: 30px;
  position: relative;
  width: 360px;
  height: 224px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  flex-shrink: 0;
}
.page-index .report-panel .report-list > li:hover .content {
  opacity: 1;
  transform: scale(1);
}
.page-index .report-panel .report-list > li a {
  position: relative;
}
.page-index .report-panel .report-list > li a:before {
  content: '';
  opacity: 0;
  transition: all 0.3s;
  background-color: rgba(51, 51, 51, 0.4);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.page-index .report-panel .report-list > li a:hover:before {
  opacity: 1;
}
.page-index .report-panel .report-list > li .content {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.8);
  color: #890c84;
  width: 296px;
  height: 126px;
  margin-top: -63px;
  margin-left: -148px;
  padding: 0 18px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  transition: all 0.3s;
  transform: scale(0.8);
}
.page-index .report-panel .report-list > li .content > .title {
  height: 50px;
  line-height: 1.5;
  font-size: 18px;
  margin-bottom: 10px;
}
.page-index .alumni-panel .panel-content {
  display: flex;
  flex-direction: column;
}
.page-index .alumni-panel .panel-content .alumni-top-wrapper {
  display: flex;
  justify-content: space-between;
}
.page-index .alumni-panel .panel-content .alumni-top-wrapper .alumni-main {
  display: flex;
  width: calc(50% - 20px);
}
.page-index .alumni-panel .panel-content .alumni-top-wrapper .alumni-main:hover .content > .title {
  color: #890c84;
}
.page-index .alumni-panel .panel-content .alumni-top-wrapper .alumni-main .img {
  width: 210px;
  height: 130px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  flex-shrink: 0;
}
.page-index .alumni-panel .panel-content .alumni-top-wrapper .alumni-main .content {
  flex-grow: 1;
  margin-left: 20px;
  border-bottom: 1px solid #e5e5e5;
}
.page-index .alumni-panel .panel-content .alumni-top-wrapper .alumni-main .content > .title {
  font-size: 18px;
  color: #333;
  line-height: 1.5;
  height: 55px;
  margin-bottom: 25px;
}
.page-index .alumni-panel .panel-content .alumni-top-wrapper .alumni-main .content .time {
  font-size: 14px;
  color: #999;
}
.page-index .alumni-panel .panel-content .alumni-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.page-index .alumni-panel .panel-content .alumni-list > li {
  width: calc(50% - 20px);
  margin-top: 25px;
  line-height: 1.5;
}
.page-index .alumni-panel .panel-content .alumni-list > li:hover .title {
  color: #890c84;
}
.page-index .alumni-panel .panel-content .alumni-list > li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.page-index .alumni-panel .panel-content .alumni-list > li .title {
  color: #666;
  font-size: 16px;
  max-width: 480px;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.page-index .alumni-panel .panel-content .alumni-list > li .time {
  color: #999;
  font-size: 14px;
  flex-shrink: 0;
}
.page-search .panel {
  margin-top: 30px;
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
}
.page-search .panel form {
  margin-bottom: 40px;
  width: 460px;
  font-size: 14px;
  border-radius: 25px;
  color: #333;
  overflow: hidden;
  height: 35px;
  line-height: 35px;
  position: relative;
}
.page-search .panel form input {
  height: 35px;
  line-height: 35px;
  width: 100%;
  border-radius: 25px;
  border: 1px solid #e5e5e5;
  padding-left: 20px;
  padding-right: 40px;
  background-color: #fafafa;
}
.page-search .panel form .search-btn {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}
.page-search .panel .search-count {
  line-height: 1.5;
  font-size: 14px;
  color: #999;
}
.page-search .panel .search-result-list {
  margin-bottom: 70px;
}
.page-search .panel .search-result-list > li {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 25px;
  padding-top: 25px;
  position: relative;
}
.page-search .panel .search-result-list > li:hover a > .title {
  color: #890c84;
}
.page-search .panel .search-result-list > li:hover a > .desc {
  color: #333;
}
.page-search .panel .search-result-list > li a > .title {
  font-size: 18px;
  line-height: 1.5;
  color: #333;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 15px;
}
.page-search .panel .search-result-list > li a > .desc {
  line-height: 1.5;
  font-size: 14px;
  color: #666;
}
.page-aside-layout .subject-content .subject-title {
  margin-bottom: 30px;
}
.page-aside-layout .article-list > li:first-child {
  padding-top: 0;
}
.page-aside-layout .events-wrapper {
  position: relative;
}
.page-aside-layout .events-wrapper:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 45px;
  bottom: 0;
  margin-left: -1px;
  border-left: 2px solid #e5e5e5;
}
.page-aside-layout .events-wrapper:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #e5e5e5;
}
.page-aside-layout .events-wrapper .year {
  margin-bottom: 15px;
}
.page-aside-layout .events-wrapper .event-list {
  padding-left: 45px;
  padding-right: 45px;
  padding-bottom: 80px;
  margin-bottom: 10px;
  position: relative;
}
.page-aside-layout .events-wrapper .event-list > li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.page-aside-layout .events-wrapper .event-list > li:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -4px;
  margin-top: -4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #e5e5e5;
  transition: all 0.3s;
}
.page-aside-layout .events-wrapper .event-list > li:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  border-bottom: 1px solid #e5e5e5;
  animation-timing-function: cubic-bezier(0.24, 1.18, 0.31, 0.94) s;
}
@keyframes line-emit {
  from {
    width: 0;
  }
  to {
    width: 60px;
  }
}
.page-aside-layout .events-wrapper .event-list > li:hover:after {
  background-color: #890c84;
}
.page-aside-layout .events-wrapper .event-list > li:hover:before {
  animation: line-emit 0.5s;
  border-color: #890c84;
}
.page-aside-layout .events-wrapper .event-list > li:hover .content .time,
.page-aside-layout .events-wrapper .event-list > li:hover .content .desc {
  color: #890c84;
}
.page-aside-layout .events-wrapper .event-list > li:nth-child(odd) {
  flex-direction: row-reverse;
}
.page-aside-layout .events-wrapper .event-list > li:nth-child(even):before {
  transform: rotateY(-180deg) translateX(100%);
}
.page-aside-layout .events-wrapper .event-list > li .img {
  border-radius: 4px;
  width: 320px;
  height: 200px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  flex-shrink: 0;
}
.page-aside-layout .events-wrapper .event-list > li .content {
  flex-shrink: 0;
  width: 320px;
  position: relative;
}
.page-aside-layout .events-wrapper .event-list > li .content .time {
  font-weight: bold;
  margin-bottom: 5px;
  line-height: 1.5;
  color: #333;
  font-size: 16px;
}
.page-aside-layout .events-wrapper .event-list > li .content .desc {
  color: #666;
  line-height: 1.5;
  font-size: 14px;
}
.page-aside-layout .org-wrapper + .org-wrapper {
  padding-top: 30px;
}
.page-aside-layout .org-wrapper .org-title {
  font-size: 18px;
  color: #333;
  line-height: 1.5;
  margin-bottom: 10px;
}
.page-aside-layout .subject-title {
  margin-bottom: 5px;
}
.page-aside-layout .main-panel {
  padding-bottom: 70px;
}
.page-aside-layout .main-panel .concat-list {
  margin: 30px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  font-size: 14px;
  color: #890c84;
  background-color: #f7f7f7;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
}
.page-aside-layout .main-panel .concat-list li {
  width: 30%;
}
.page-aside-layout .main-panel .concat-list li .icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  margin-bottom: 10px;
}
.page-aside-layout .main-panel .concat-list li .desc {
  line-height: 1.2;
}
.page-aside-layout .main-panel .map {
  height: 445px;
  width: 100%;
  border: 1px solid #e5e5e5;
}
.page-detail .main-panel {
  padding-left: 90px;
  padding-right: 90px;
  padding-bottom: 70px;
}
.page-detail .main-panel .meta {
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
  padding-top: 50px;
  padding-bottom: 30px;
}
.page-detail .main-panel .meta .title {
  font-size: 18px;
  color: #333;
  line-height: 1.5;
  margin-bottom: 20px;
}
.page-detail .main-panel .meta .time {
  font-size: 14px;
  color: #999;
  line-height: 1.2;
}
.page-course .teachers-intro-panel .teachers-swiper {
  width: 100%;
  overflow: hidden;
  padding: 15px;
  margin: -15px;
  box-sizing: content-box;
}
.page-course .teachers-intro-panel .teachers-swiper .swiper-slide {
  background-color: #fff;
  height: 350px;
}
.page-course .teachers-intro-panel .mini-page {
  justify-content: flex-end;
}
.course-panel {
  display: flex;
}
.course-panel .img {
  margin-right: 40px;
  width: 600px;
  height: 374px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  flex-shrink: 0;
}
.course-panel .content {
  flex-grow: 1;
  padding-top: 25px;
}
.course-panel .content .panel-title-deco {
  margin-bottom: 25px;
}
.course-panel .content .text .load-more {
  margin-top: 35px;
}
.course-panel .content .text .load-more:after {
  transform: rotate(-90deg);
}
.page-course-index .panel {
  padding-top: 30px;
}
.page-course-index .course-list {
  margin-left: -30px;
  display: flex;
}
.page-course-index .course-list > li {
  margin-left: 30px;
  width: 360px;
  position: relative;
}
.page-course-index .course-list > li:before {
  content: '';
  opacity: 0;
  transition: all 0.3s;
  background-color: rgba(51, 51, 51, 0.4);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.page-course-index .course-list > li:hover:before {
  opacity: 1;
}
.page-course-index .course-list > li:before {
  height: 224px;
}
.page-course-index .course-list > li:hover a > .desc {
  color: #890c84;
}
.page-course-index .course-list > li:hover a .img .mask-content {
  opacity: 1;
  transform: translateY(0);
}
.page-course-index .course-list > li a {
  display: block;
}
.page-course-index .course-list > li a .img {
  position: relative;
  width: 100%;
  height: 224px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  flex-shrink: 0;
  margin-bottom: 15px;
}
.page-course-index .course-list > li a .img .mask-content {
  transition: all 0.3s;
  opacity: 0;
  transform: translateY(5px);
  position: absolute;
  left: 0;
  right: 0;
  padding: 0 10%;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.page-course-index .course-list > li a .img .mask-content .desc {
  color: #fff;
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 10px;
}
.page-course-index .course-list > li a .img .mask-content .btn {
  width: 80px;
}
.page-course-index .course-list > li a .desc {
  text-align: center;
  color: #333;
  line-height: 1.2;
  font-size: 16px;
}
.page-legal-business-lecture .main-panel {
  padding-bottom: 70px;
}
.page-legal-business-lecture .main-panel .panel-title-bar {
  margin-bottom: 5px;
}
.page-legal-business-lecture .main-panel .legal-business-lecture-list > li:first-child {
  padding-top: 0;
}
.page-annual-report .subject-content .subject-title {
  margin-bottom: 30px;
}
.page-employment .teachers-intro-panel .tab-list {
  margin-bottom: 0;
}
.page-employment .join-table tr {
  cursor: pointer;
}
.page-employment .pagination {
  margin-top: -25px !important;
}
.member-list-wrapper {
  padding-bottom: 60px;
}
.member-list-wrapper.member-list-preview .member-list {
  padding-top: 5px;
  overflow: hidden;
  height: 540px;
  padding-bottom: 30px;
  box-sizing: content-box;
}
.member-list-wrapper.member-list-preview .btn-get-member-list-wrapper {
  display: block;
}
.member-list-wrapper .btn-get-member-list-wrapper {
  display: none;
  margin-top: 30px;
}
.member-list-wrapper:last-child {
  padding-bottom: 0;
}
.ajax-page-loading {
  position: relative;
}
@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.ajax-page-loading.ajax-page-loading__active .ajax-page-loading-icon,
.ajax-page-loading.ajax-page-loading__active .loading-mask {
  display: block;
}
.ajax-page-loading .ajax-page-loading-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -12px;
  z-index: 11;
  animation: rotate 1s infinite linear;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAACeUlEQVRIS8WVTWgTQRTH39tNK1i/D15VPHnw5EH0IC1kU4OtoKWCCBLNJkJzquDBsJtsNyl400sLJhMiWBBajIeCbbIr9aaIJwXxIOJJhILoqViTeWW22WRrk2abHNzT7Lz3/r+Z997MIOziiz+wDvI/fJKlhw2/YejX8bZZiUiIRdd//8DA3od3z691ivcNUDPWcwC42hBEZEwLxnYNmDDm963Lh3OA8I5pyiNXQM1WkkA43QTApNfeDrRtB2rGItcZARfyevCa+I9PW5dqhAkkCnvnXd9otjIuxgUttOCFbQGoU2UDJCntdWC64iuNnoUNMV153VykR83pkr/wy2N8mteVm53yLOzRrF1EoHWmKXfa7kAY6t0ySgQ/EOEV05WSH4DvGvQi1irWV357gTqAzeLKI2KMRCv5lHKvF9GIsXT8iRH+5ug5BcpYvxHgQF10rSbvOVZMXljtBhLL2CMEtChiRQdiPPPyJIe+L14xqcZP5Yzhz90AnG4iiojYaq16YjNFpl0CpCt1wbdMV851Iy5iRHoCsjwGCN+ZFnrWKPIts3w2QNKhfFopdyv+/7roX7Jq2nEJ6E0upXzsdTc7XnZEMFtIKYlOEDVbuY4kOTUk5C9E7j3XTTM8lrVvENFcc4ZWmR462hGw9a0oMV0ZawmIm9ZpjvChIYi4yLTgZfdfNctngGCU+vvnCveHGq0dy9oTRDTjHCzERF4LzrYEOIfOtGYQaRwBvxLny+77q2asQQBYcQO913jUWD4iBQJhYePV6lLBuPizLaBdOra9FZxP+Xn8fV92TnpQet9qBzvVyDfAOfEiTZwPyn3y/ONk8FOn4gv7BmVw5NCuuxYDAAAAAElFTkSuQmCC) no-repeat center;
  width: 24px;
  height: 24px;
  background-origin: content-box;
  background-size: contain;
  display: inline-block;
  display: none;
}
.ajax-page-loading .loading-mask {
  background-color: #fff;
  opacity: 0.3;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: none;
}
